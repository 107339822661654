<template>
  <div class="news" v-if="newsList.length > 0">
    <div class="container">
      <div class="page-breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: `/fund/${fund.id}` }" v-if="fund">{{ getText(fund, 'name') }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: `/projects/${fund.id}` }" v-if="fund">{{ $t('fund.FundPortfolio') }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: `/project-detail/${$route.params.id}?fundId=${fund.id}` }" v-if="fund && project">{{ getText(project, 'name') }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: `/project/${$route.params.id}` }" v-else-if="project">{{ getText(project, 'name') }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t('moreNews') }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="news-list" >
        <el-row :gutter="32">
          <el-col :span="12" v-for="item in newsList" :key="item.id">
            <div class="item" @click="toNewsDetail(item)">
              <div class="item-pic"><el-image style="width: 168px; height: 110px" :src="item.coverImage" fit="cover"></el-image></div>
              <div class="item-content">
                <div class="title">{{ getText(item, 'title') }}</div>
                <div class="time">{{ item.publishTime }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-pagination @current-change="getList" :current-page.sync="page" :page-size="size" background layout="prev, pager, next" :total="totalElements" :hide-on-single-page="true"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'NewsList',
  data() {
    return {
      size: 20,
      page: 1,
      totalElements: 0,
      newsList: [],
      fund: null,
      project: null
    }
  },
  watch: {
    $route() {
      this.page = 1
      this.getList()
      this.getProject()
    },
  },
  created() {
    this.page = 1
    this.getList()
    this.getProject()
  },
  methods: {
    async getList() {
      const res = await api.getArticles(
        {
          articleTypes: ['PROJECT'],
          projectId: this.$route.params.id,
        },
        `size=${this.size}&page=${this.page - 1}`
      )

      const { content, totalElements } = res.data
      this.newsList = content
      this.totalElements = totalElements
    },
    getFund(id) {
      api.getFund(id).then((res) => {
        this.fund = res.data
      })
    },
    getProject() {
      let data = {}
      const fundId = this.$route.query.fundId
      if (fundId) {
        data.fundId = fundId
        this.getFund(fundId)
      }
      api.getProject(this.$route.params.id, data).then((res) => {
        this.project = res.data
      })
    },
  },
}
</script>